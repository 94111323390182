<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Kategori Berita</b> yang ada di Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/website/post-category/add')"
      >Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <div>
              <div class="overflow-auto">
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                id="my-table"
                :style="'white-space: nowrap'"
                responsive="sm"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '25%' : '' }"
                  />
                </template>
                <template #cell(actions)='data'>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="$router.push({ path: '/website/post-category/detail/' + data.item.id })"
                  > <i class="fas fa-eye px-0"></i> </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="$router.push({ path: '/website/post-category/edit/' + data.item.id })"
                  > <i class="fas fa-edit px-0"></i> </b-button>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                  > <i class="fas fa-trash px-0"></i> </b-button>
                </template>
              </b-table>
              <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              class="mt-4"
              ></b-pagination>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: "List",

  components: {
    Card
  },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: null,
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async pagination() {
      let filterParams = ``;
      let response = await module.paginate(
        `website-post-categories`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination
      
      let c, b
      b = this.perPage * (this.currentPage - 1) + 1  
      for(c = 0; c < response.data.length; c++){
        response.data[c].number = b + c
      }
      this.items = response.data;
      this.totalRows = pagination.total;
      this.dataLoaded = true
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete('website-post-categories/' + id)
      // If Deleted
      if (result) {
        this.list()
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kategori Berita" }])
    // Get Data
    this.pagination()
  },

}

</script>
